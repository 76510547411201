import { useMemo, useSyncExternalStore } from "react"

function subscribe(callback) {
    let throttleTimeout = null
    const throttleDelay = 1000 // ms throttle delay

    const throttledCallback = () => {
        if (throttleTimeout === null) {
            throttleTimeout = setTimeout(() => {
                callback()
                throttleTimeout = null
            }, throttleDelay)
        }
    }

    window.addEventListener("resize", throttledCallback)
    return () => {
        window.removeEventListener("resize", throttledCallback)
        if (throttleTimeout !== null) {
            clearTimeout(throttleTimeout)
        }
    }
}

function subscribeNoResize(callback) {
    return () => {}
}

function useDimensions(ref) {
    const dimensions = useSyncExternalStore(
        subscribe,
        () => JSON.stringify({
            width: ref.current?.offsetWidth ?? 0,
            height: ref.current?.offsetHeight ?? 0,
        })
    )
    return useMemo(() => JSON.parse(dimensions), [dimensions])
}

function useDimensionsOnce(ref) {
    const dimensions = useSyncExternalStore(
        subscribeNoResize,
        () => JSON.stringify({
            width: ref.current?.offsetWidth ?? 0,
            height: ref.current?.offsetHeight ?? 0,
        })
    )
    return useMemo(() => JSON.parse(dimensions), [dimensions])
}

function useDimensionsOnceForImage(ref) {
    const dimensions = useSyncExternalStore(
        subscribeNoResize,
        () => JSON.stringify({
            width: ref.current?.offsetWidth*window.devicePixelRatio ?? 0,
            height: ref.current?.offsetHeight*window.devicePixelRatio ?? 0,
        })
    )
    return useMemo(() => JSON.parse(dimensions), [dimensions])
}


export { useDimensions, useDimensionsOnce, useDimensionsOnceForImage }