
export const ProductColors = {
    '1536': ['#C6B177', '#008000'], // Guld/Grön
        '956': '#008000', // Grön
        '84': '#FFDAB9', // Peach
        '952': '#C19A6B', // Camel
        '99': '#000000', // Svart
        '793': ['#000000', '#F5F5DC'], // Svart/Beige
        '1549': ['#008000', '#C6B177'], // Grön/Guld
        '2444': ['#0000FF', '#FFFFFF', '#FF0000'], // Blå/Vit/Röd
        '2280': ['#C6B177', '#F7E7CE'], // Guld/Champagne
        '2411': '#800020', // Burgundy
        '187': ['#8B4513', '#C6B177'], // Brun/Guld
        '218': '#FFC0CB', // Rosé
        '1533': ['#008000', '#8F8E8F'], // Grön/Stål
        '1158': '#F5F5DC', // Beige
        '1142': ['#008000', '#F5F5DC'], // Grön/Beige
        '839': '#808080', // Grå
        '769': ['#000000', '#C6B177'], // Svart/Guld
        '184': ['#8F8E8F', '#000000'], // Stål/Svart
        '127': ['#C6B177', '#D3CFC5'], // Guld/Melange
        '1316': '#1C1C1C', // Antiksvart
        '1184': '#D3CFC5', // Melange
        '160': ['#8B4513', '#000000', '#8F8E8F'], // Brun/Svart/Stål
        '18': '#8F8E8F', // Stål
        '1495': ['#8B4513', '#008000'], // Brun/Grön
        '2450': '#FF0000', // Röd
        '1235': ['#8B4513', '#40E0D0'], // Brun/Turkos
        '498': ['#8F8E8F', '#FFC0CB'], // Stål/Rosa
        '1502': ['#8B4513', '#0000FF'], // Brun/Blå
        '727': '#8B4513', // Brun
        '1136': ['#0000FF', '#F5F5DC'], // Blå/Beige
        '1655': ['#C6B177', '#FFFFFF'], // Guld/Vit
        '3': '#2A3439', // Gun Metal
        '181': ['#C6B177', '#000000'], // Guld/Svart
        '212': ['#8F8E8F', '#C6B177'], // Stål/Guld
        '24': '#C6B177', // Guld
        '1652': ['#8F8E8F', '#FFFFFF'], // Stål/Vit
        '861': '#0000FF', // Blå
        '186': ['#8B4513', '#8F8E8F'], // Brun/Stål
        '118': ['#8F8E8F', '#D3CFC5'], // Stål/Melange
        '505': ['#C6B177', '#FFC0CB'], // Guld/Rosa
        '1507': ['#8B4513', '#000000'], // Brun/Svart
        '167': ['#8B4513', '#000000', '#C6B177'], // Brun/Svart/Guld
        '510': ['#8F8E8F', '#808080'], // Stål/Grå
        '1246': '#000080', // Navy
        '514': ['#C6B177', '#808080'], // Guld/Grå
        '2412': '#EAE0C8' // Oyster
}