import React, { useRef } from "react";
import { useDimensionsOnceForImage } from "../../helpers/helpers";

const Image = props => {
    const{values, classes} = props;

    const ref = useRef(null)
    const {width, height} = useDimensionsOnceForImage(ref)

    const hasDisplayDeviceProps =
        "displayDeviceLg" in values ||
        "displayDeviceMd" in values ||
        "displayDeviceSm" in values ||
        "displayDeviceXs" in values;

    const isVisible =
        !hasDisplayDeviceProps ||
        values?.displayDeviceLg ||
        values?.displayDeviceMd ||
        values?.displayDeviceSm ||
        values?.displayDeviceXs;

    if (!isVisible) {
        return null;
    }

    const visibilityClasses = [
        hasDisplayDeviceProps && !values?.displayDeviceLg && "hidden-lg",
        hasDisplayDeviceProps && !values?.displayDeviceMd && "hidden-md",
        hasDisplayDeviceProps && !values?.displayDeviceSm && "hidden-sm",
        hasDisplayDeviceProps && !values?.displayDeviceXs && "hidden-xs",
    ]
        .filter(Boolean)
        .join(" ");

    return (
        <div ref={ref} className={`widget-image ${visibilityClasses}`}>
            {values.linkUrl ? (
                <a href={values.linkUrl}>
                    <img src={ width > 0 ? values.url + '&width=' + width : ''} width="100%"/>
                </a>
            ) : (
                <img src={ width > 0 ? values.url + '&width=' + width : ''} width="100%"/>
            )}
        </div>
    );
}

export default Image;