import React, { useState } from 'react';
import { BsChevronRight, BsChevronDown } from "react-icons/bs";

import { useTranslation } from 'react-i18next';

import '../../style/ProductInformation.scss';
const ProductInformation = ({ product }) => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const description = product?.description;
  const sizeInfo = product?.properties?.find(prop => prop.type === "sizeDescription");
  const materialInfo = product?.properties?.find(prop => prop.type === "materialDetailed");
  const locktype = product?.properties?.find(prop => prop.type === "locktype");
  //const shippingreturnInfo = product?.properties?.find(prop => prop.type === "shippingReturn");
  const usp1Info = product?.properties?.find(prop => prop.type === "usp1");

  const cleanText = (text) => {
    // Remove HTML entities like &nbsp; and replace them with space
    let cleanedText = text.replace(/&nbsp;/g, " ");
    
    // Remove any other HTML tags (e.g., <div>, <br>, etc.)
    cleanedText = cleanedText.replace(/<\/?[^>]+(>|$)/g, "").trim();
    
    return cleanedText;
  };

   const uspList = usp1Info?.value
   ?.split("<div>")
   .map(cleanText)
   .filter(item => item.length > 0);

  return (
    <div className="product-information">
      {description &&  (
          <div className="info-section">
            <div className="info-title" onClick={() => handleToggle(0)}>
              <p>{t('shop-productInformation')}</p>
              {openIndex === 0 ? (
                <BsChevronDown className="icon" />
              ) : (
                <BsChevronRight className="icon" />
              )}
            </div>
            {openIndex === 0 && (
               <div className="info-text" dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </div>
      )}

      {sizeInfo &&  (
          <div className="info-section">
            <div className="info-title" onClick={() => handleToggle(1)}>
              <p>{t('pim-consumptionBundleMeasurments')}</p>
              {openIndex === 1 ? (
                <BsChevronDown className="icon" />
              ) : (
                <BsChevronRight className="icon" />
              )}
            </div>
            {openIndex === 1 && (
               <div className="info-text" dangerouslySetInnerHTML={{ __html: sizeInfo.value }} />
            )}
          </div>
      )}

      {materialInfo && (
          <div className="info-section">
            <div className="info-title" onClick={() => handleToggle(2)}>
              <p>Material</p>
              {openIndex === 2 ? (
                <BsChevronDown className="icon" />
              ) : (
                <BsChevronRight className="icon" />
              )}
            </div>
            {openIndex === 2 && (
               <div className="info-text" dangerouslySetInnerHTML={{ __html: materialInfo.value }} />
            )}
          </div>
      )}

      {locktype && (
          <div className="info-section">
            <div className="info-title" onClick={() => handleToggle(3)}>
              <p>{t('shop-ClaspType')}</p>
              {openIndex === 3 ? (
                <BsChevronDown className="icon" />
              ) : (
                <BsChevronRight className="icon" />
              )}
            </div>
            {openIndex === 3 && (
               <div className="info-text" dangerouslySetInnerHTML={{ __html: locktype.value }} />
            )}
          </div>
      )}
 
 {/* 
      {shippingreturnInfo && (
          <div className="info-section">
            <div className="info-title" onClick={() => handleToggle(4)}>
              <p>{t('shop-checkoutFreightCostAndReturn')}</p>
              {openIndex === 4 ? (
                <BsChevronDown className="icon" />
              ) : (
                <BsChevronRight className="icon" />
              )}
            </div>
            {openIndex === 4 && (
               <div className="info-text" dangerouslySetInnerHTML={{ __html: shippingreturnInfo.value }} />
            )}
          </div>
      )}
*/}
      {uspList && uspList.length > 0 && (
        <ul className="custom-list">
          {uspList.map((point, index) => (
            <li className='custom-list li' key={index}>{point}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProductInformation;
