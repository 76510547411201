import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDimensionsOnceForImage } from "../../helpers/helpers";

const Text = props => {
    const{values, classes} = props;
    const admin = useSelector(state => state.admin);
    const ref = useRef(null)
    const {width, height} = useDimensionsOnceForImage(ref)
    const [parsedText, setParsedText] = useState(values.parsedText);

    const styles = `
        .widget-text.admin-mode {
            height: auto;
            min-height: 50px;
            width: auto;
            min-width: 100px;
            padding: 2px;
            outline: none;
        }
        
        .widget-text.admin-mode:focus {
            border: 1px solid #000;
            border-color: #007BFF;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
    `;

    useEffect(() =>  {
        setParsedText(values.parsedText.replace(
            /(<img\s+[^>]*\bsrc\s*=\s*["'])([^"']*\/_itcm\/file\.php\?id=\d+)(["'][^>]*>)/gi,
            '$1$2&width=' + width + '$3'
        ));
    }, [width]);

    return(

        <>
            <style>{styles}</style>
            <div
                ref={ref}
                id={'inlineEditor' + values.id}
                className={`widget-text ${classes} ${admin ? 'admin-mode' : ''}`}
                contentEditable={admin}
                dangerouslySetInnerHTML={{ __html: admin ? values.text : parsedText }}
            />
        </>
        
        //<div id={'inlineEditor' + values.id} className={'widget-text ' + classes} contenteditable={admin} dangerouslySetInnerHTML={{__html: values.text}}/>
    
    );
}

export default Text;