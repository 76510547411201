import React, { useState, useEffect } from "react";
import Text from './Text.js';
import '../../css/main.css';
import ColumnsRecursive from "./ColumnsRecursive";
import Image from "./Image";
import PimViewer from "./PimViewer"
import PimBrowser from "./PimBrowser"
import GenericWidget from "./GenericWidget"
import ProductSlider from "./ProductSlider.js";

const Widget = props => {

    const{widget, displayLg, displayMd, displaySm, displayXs, setShowMiniCart, handleFavoriteQuantityChange, favorite} = props;

    let widgetObj = {};
    let hideXS = 'hidden-xs';
    let hideSM = 'hidden-sm';
    let hideMD = 'hidden-md';
    let hideLG = 'hidden-lg';
    let classString = '';
    if(!displayXs){
        classString = classString + ' ' + hideXS;
    }
    if(!displaySm){
        classString = classString + ' ' +  hideSM;
    }
    if(!displayMd){
        classString = classString + ' ' +  hideMD;
    }
    if(!displayLg){
        classString = classString + ' ' +  hideLG;
    }
    let type = '';
    if(widget.widget){
        type = widget.widget.type;
        widgetObj = widget.widget;
    }else{
        type = widget.type;
        widgetObj = widget
    }

    const widgetComponent = () => {
        switch (widgetObj.type) {
            case "Text":
                return <Text classes={classString} values={widgetObj}/>;
            case "Columns":
                return <ColumnsRecursive classes={classString} values={widgetObj}/>;
            case "PIM Browser":
                return <PimBrowser values={widgetObj} handleFavoriteQuantityChange={handleFavoriteQuantityChange} favorite={favorite}/>;
            case "PIM Viewer":
                return <PimViewer classes={classString} values={widgetObj} setShowMiniCart={setShowMiniCart} handleFavoriteQuantityChange={handleFavoriteQuantityChange} favorite={favorite}/>;
            case "Image":
                return <Image classes={classString} values={widgetObj} />;
                case "Shop Product Slider":
                return <ProductSlider classes={classString} values={widgetObj} handleFavoriteQuantityChange={handleFavoriteQuantityChange} favorite={favorite}/>;
            default:
                return <GenericWidget classes={classString} values={widgetObj} />;
        }
    }

    return(
        <>
            <widget id={widgetObj.id} type={widgetObj.type}>
                { widgetComponent() }
            </widget>
        </>

    );
}




export default Widget;