import React, { useState, useEffect } from "react";
import HtmlInjector from "../HtmlInjector";

const GenericWidget = props => {
    const{values, classes} = props;
    const [dialog, setDialog] = useState("");
    useEffect(() => {

        // Get the full query string (e.g., ?name=John&age=30)
        const queryString = window.location.search;

        // Create a URLSearchParams object
        const urlParams = new URLSearchParams(queryString);
        urlParams.append('route', window.location.pathname);
        urlParams.append('id', values.id);

        fetch('/Api/Widget/Get?' + urlParams.toString())
            .then(response => response.json())
            .then(data => setDialog(data.html))
    }, [] )

    return <HtmlInjector htmlSnippet={dialog} />;
}

export default GenericWidget;