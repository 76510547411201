import React from "react";
import Widget from "./Widget";

const ColumnsRecursive = (props) => {
    const { values, classes } = props;
    let columns = values.columns;

    const visibilityClasses = [
        !values.displayDeviceLg && "hidden-lg",
        !values.displayDeviceMd && "hidden-md",
        !values.displayDeviceSm && "hidden-sm",
        !values.displayDeviceXs && "hidden-xs",
    ]
        .filter(Boolean)
        .join(" "); 
    
    return (
        <div 
            className={`widget-columns row no-gutters ${visibilityClasses}`} 
            style={{ 
                overflowX: "hidden", 
                width: '100%', 
                display: "flex", 
                flexWrap: 'wrap', 
                marginLeft: 0, 
                marginRight: 0 
            }}
        >
            {columns.map((widget, index) =>
                widget.columns && widget.columns.length > 0 ? (
                    <ColumnsRecursive key={`recursive-${index}`} values={widget} />
                ) : null
            )}
            {columns.map((widget, index) => {
                const columnClass = [
                    widget.size && `col-md-${widget.size}`,
                    widget.sizeLg && `col-lg-${widget.sizeLg}`,
                    widget.sizeSm && `col-sm-${widget.sizeSm}`,
                    widget.sizeXs && `col-xs-${widget.sizeXs}`,
                ]
                    .filter(Boolean)
                    .join(" ");

                return (
                    <div
                        key={`column-${index}`}
                        className={`widget-columns-column ${columnClass}`}
                        style={{
                            paddingBottom: widget.marginBottom || 0,
                            paddingTop: widget.marginTop || 0,
                            paddingRight: widget.marginRight || 0,
                            paddingLeft: widget.marginLeft || 0,
                            marginBottom: widget.marginBottom || 1,
                        }}
                    >
                        <Widget
                            widget={widget}
                            displayLg={widget.displayDeviceLg}
                            displayMd={widget.displayDeviceMd}
                            displaySm={widget.displayDeviceSm}
                            displayXs={widget.displayDeviceXs}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ColumnsRecursive;
