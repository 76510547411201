import React, { useState, useEffect } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
//import '../examples/css/VerticalSlider.css';

import '../../style/VerticalSlider.scss';

const VerticalSlider = ({ onImageSelect, images }) => {
  const allImages = images || [];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(allImages[0]);
  const numberOfImages = allImages.length;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    
    if (allImages.length > 0 && !selectedImage) {
      setSelectedImage(allImages[0]);
    }

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);

  }, [allImages, selectedImage]);

  const handleScroll = (direction) => {
    setCurrentImageIndex((prevIndex) => {
      let newIndex = prevIndex + direction;

      if (newIndex >= numberOfImages - 3) {
        newIndex = numberOfImages - 3;
      } else if (newIndex < 0) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  const handleImageClick = (img) => {
    setSelectedImage(img);
    onImageSelect(img);
  };

  const showUpButton = currentImageIndex > 0;
  const showDownButton = numberOfImages > 3 && currentImageIndex < numberOfImages - 3;

  return (
    <> 
      <div className="row">
          {/* Desktop: Show Vertical Slider */}
          {windowWidth > 991 && (
        <div className="col-lg-2 col-md-3 col-sm-2 p-1">
          <div className="slider-container">
            {showUpButton && (
              <button onClick={() => handleScroll(-1)} className="scroll-button up">
                <BsChevronUp />
              </button>
            )}
              <div
                className="slider"
                style={{
                  transform: `translateY(-${currentImageIndex * 259}px)`,
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                {allImages.map((img, index) => (
                  <img
                    key={index}
                    src={img.filter}
                    alt={`Slide ${index}`}
                    className={`slider-image ${index === currentImageIndex ? 'active-image' : ''}`}
                    onClick={() => handleImageClick(img)}
                  />
                ))}
              </div>
            {showDownButton && (
              <button onClick={() => handleScroll(1)} className="scroll-button down">
                <BsChevronDown />
              </button>
            )}
           
          </div> 
         </div>
        )}

      <div className="col-lg-10 col-md-9 col-sm-10 p-1">
          {selectedImage && (
            <div className="large-image-container">
              <img src={selectedImage.medium} alt="Selected" className="selected-image" />
            </div>
          )}
        </div>

        {/* Show Horizontal Slider on Tablet/Phone */}
        {windowWidth <= 991 && numberOfImages > 1 && (
          <div className="horizontal-slider-container">
            <div className="horizontal-slider">
              {allImages.map((img, index) => (
                <img
                  key={index}
                  src={img.filter}
                  alt={`Slide ${index}`}
                  className={`slider-image ${index === currentImageIndex ? 'active-image' : ''}`}
                  onClick={() => handleImageClick(img)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
   </>
  );
};

export default VerticalSlider;
