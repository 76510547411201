import './css/main.css';
import React from "react";
import { CookiesProvider } from 'react-cookie';
import Content from "./pages/content";
import Health from "./pages/health";
import {Routes, Route} from "react-router-dom";
import store from './store'
import {Provider} from "react-redux";

function App() {

    return (
        <Provider store={store}>
            <CookiesProvider>
                <div className="App">
                    <Routes>
                        <Route path="health" element={<Health/>}/>
                        <Route path="*" element={<Content/>}/>
                    </Routes>
                </div>
            </CookiesProvider>
        </Provider>
    );
}

export default App;