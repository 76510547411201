import { createSlice } from "@reduxjs/toolkit";

export const signInSlice = createSlice({
    name: "admin",
    initialState: false,
    reducers: {
        updateSingInData: (state, action) => action.payload,
    },
});

export const { updateSingInData } = signInSlice.actions;

export default signInSlice.reducer;