// src/SliderWithLargeImage.js
import React, {useEffect, useState} from 'react';
import VerticalSlider from './VerticalSlider';
import ProductDetails from './ProductDetails';
import ProductInformation from './ProductInformation';
import Product from './Product';

import '../../style/PimViewer.scss';

const PimViewer = (props) => {

  const { values, classes, setShowMiniCart, handleFavoriteQuantityChange, favorite  } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [product, setProduct] = useState(null);

  const [productFake, setProductFake] = useState(
      {
        material: 'Guldpläterat',
        title: "Gold Necklace",
        price: 199.99,
        images: [
            "https://s3.eu-central-1.amazonaws.com/cavaxo/d6bca1bfc53102250ea4a1d18d3a9821ce3209f44e2131c4.png",
            "https://s3.eu-central-1.amazonaws.com/cavaxo/faf15d520e048ae1c7245d1e008cb79564e053bd61e55925.png"
        ], 
        selectedColor: "#C6B177",
        color: ['Silver','Guld'],
        size: ['2 mm', '3 mm', '5 mm', '5 mm',],
    },
  );

  const fakeProducts = new Array(2).fill(productFake); 

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const visibilityClasses = [
      !values.displayDeviceLg && "hidden-lg",
      !values.displayDeviceMd && "hidden-md",
      !values.displayDeviceSm && "hidden-sm",
      !values.displayDeviceXs && "hidden-xs",
    ]
    .filter(Boolean)
    .join(" "); 
  
  useEffect(() => {
    fetch('/Api/System/Pim/IndexManager/Product?sku=' + values.sku)
        .then((res) => res.json())
        .then((product) => {
          setProduct(product)
          setSelectedImage(product.images[0]);
        });
  }, [])

  return (
    <> 
      <div className={`pim-viewer ${visibilityClasses}`}>
        <div className="row justify-content-center">
          {/* Image Slider */}
          <div className="col-lg-5 col-md-12 col-sm-12">
            {product && (
              <VerticalSlider
                images={product.images}
                onImageSelect={handleImageSelect}
              />
            )}
          </div>

          {/* Product Details & Information */}
          <div className="col-lg-4 col-md-12 col-sm-12">
            {product && <ProductDetails product={product} setShowMiniCart={setShowMiniCart} handleFavoriteQuantityChange={handleFavoriteQuantityChange} favorite={favorite}/>}
            {product && <ProductInformation product={product} />}
          </div>
        </div>
        
      </div>
    </>


  );
};

export default PimViewer;
