import React, { useEffect, useRef } from "react";

const HtmlInjector = ({ htmlSnippet }) => {
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            // Inject the HTML into the div
            divRef.current.innerHTML = htmlSnippet;

            // Extract and evaluate any <script> tags in the HTML
            const scripts = divRef.current.querySelectorAll("script");
            scripts.forEach((script) => {
                const newScript = document.createElement("script");
                newScript.textContent = script.textContent;
                document.body.appendChild(newScript);
                document.body.removeChild(newScript);
            });
        }
    }, [htmlSnippet]);

    return <div ref={divRef} />;
};

export default HtmlInjector;